import axios from 'axios'

const API_ENDPOINT = 'logs'

export default {

    all(data) {
        return axios.get(API_ENDPOINT, {params: data})
    },

    find(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    }

}
