import Dashboard from "./components/Dashboard";

export const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        iconCls: 'el-icon-menu',
        order: 500
    },
];
