import * as types from './types'
import dutyPhoneApi from '../api'

export const actions = {
    async [types.DUTY_PHONE_FETCH]({commit}, data = null) {
        commit(types.DUTY_PHONE_SET_LOADING, true)
        const response = await dutyPhoneApi.all(data)
        commit(types.DUTY_PHONE_OBTAIN, response.data.data)
        commit(types.DUTY_PHONE_META, response.data.meta)
        commit(types.DUTY_PHONE_SET_LOADING, false)
    }
}
