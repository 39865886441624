import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        logs: [],
        logsMeta: [],
        logsLoading: true,
    },
    getters: {
        logs: state => state.logs,
        logsMeta: state => state.logsMeta,
        logsLoading: state => state.logsLoading,
    },
    mutations: {
        [types.LOG_OBTAIN](state, logs) {
            state.logs = logs
        },
        [types.LOG_CLEAR](state) {
            state.logs = []
        },
        [types.LOG_SET_LOADING](state, loading) {
            state.logsLoading = loading
        },
        [types.LOG_META](state, meta) {
            state.logsMeta = meta
        },
    },
    actions
}
