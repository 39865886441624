import * as types from './types'
import { requireAuthCheck } from '../../includes/helpers/helpers'

let collapsed = false
if (window.innerWidth < 768) {
    collapsed = true;
}

export const store = {
    state: {
        isCollapsed: collapsed
    },
    mutations: {
        [types.TOGGLE_COLLAPSE](state) {
            state.isCollapsed = !state.isCollapsed
        },
        [types.SET_COLLAPSE](state, status) {
            state.isCollapsed = status
        },
    },
    getters: {
        coreIsCollapsed: state => state.isCollapsed,

        isAvailable: (state) => (access) => {
            let allowed = requireAuthCheck(access)
            if (allowed.length){
                return true
            }

            return false
        }
    }
}
