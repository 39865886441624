import helper from '../../includes/helpers/helpers'

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/users',
        component: page('UserList'),
        name: 'Users',
        iconCls: 'el-icon-user',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'users'
        },
        order: 507
    }
]
