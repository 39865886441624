import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        reports: [],
        reportsMeta: [],
        reportsLoading: true,
    },
    getters: {
        reports: state => state.reports,
        reportsMeta: state => state.reportsMeta,
        reportsLoading: state => state.reportsLoading,
    },
    mutations: {
        [types.REPORT_OBTAIN](state, reports) {
            state.reports = reports
        },
        [types.REPORT_CLEAR](state) {
            state.reports = []
        },
        [types.REPORT_SET_LOADING](state, loading) {
            state.reportsLoading = loading
        },
        [types.REPORT_META](state, meta) {
            state.reportsMeta = meta
        },
    },
    actions
}
