import * as types from './types'
import reportApi from '../api'

export const actions = {
    async [types.REPORT_FETCH]({commit}, data = null) {
        commit(types.REPORT_SET_LOADING, true)
        const response = await reportApi.all(data)
        commit(types.REPORT_OBTAIN, response.data.data)
        commit(types.REPORT_META, response.data.meta)
        commit(types.REPORT_SET_LOADING, false)
    },
}
