import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        listPharms: [],
        listPharmsMeta: [],
        listPharmsLoading: true,
    },
    getters: {
        listPharms: state => state.listPharms,
        listPharmsMeta: state => state.listPharmsMeta,
        listPharmsLoading: state => state.listPharmsLoading,
    },
    mutations: {
        [types.LIST_PHARM_OBTAIN](state, listPharms) {
            state.listPharms = listPharms
        },
        [types.LIST_PHARM_CLEAR](state) {
            state.listPharms = []
        },
        [types.LIST_PHARM_SET_LOADING](state, loading) {
            state.listPharmsLoading = loading
        },
        [types.LIST_PHARM_META](state, meta) {
            state.listPharmsMeta = meta
        },
    },
    actions
}
