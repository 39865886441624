<template>
    <div>
        <el-menu :default-active="$route.path" class="el-menu-"
                 mode="horizontal"
                 @open="handleOpen"
                 @close="handleClose"
                 @select="handleSelect"
                 router
                 :collapse="coreIsCollapsed">
            <template v-for="(item,index) in admin_routes">
                <el-menu-item :route="item" v-if="!item.children && !item.hidden && menuItemIsAvailable(item)"
                              :index="item.path"
                              :class="item.class"
                              :title="$t(item.name)">
                    <i :class="item.iconCls || 'el-icon-s-claim'"></i>
                    <span slot="title">{{ $t(item.name) }}</span>
                </el-menu-item>
                <el-submenu :index="index+''" v-if="item.children && !item.hidden && menuItemIsAvailable(item)" :class="item.class">
                    <template slot="title">
                        <i :class="item.iconCls || 'el-icon-s-claim'"></i>
                        <span slot="title">{{ $t(item.name) }}</span>
                    </template>
                    <el-menu-item v-for="child in item.children"
                                  v-if="!child.hidden"
                                  :route="child"
                                  :index="child.path"
                                  :key="child.path">
                        <span slot="title">{{ $t(child.name) }}</span>
                    </el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import {SET_COLLAPSE} from "../store/types";

    export default {
        name: "Sidebar",
        components: {},
        data: () => ({
            windowWidth: window.innerWidth,
        }),
        created() {

        },
        methods: {
            ...mapMutations([SET_COLLAPSE]),
            menuItemIsAvailable(item) {
                let allowed = item.hidden ? !item.hidden : true;

                if (allowed  && item.meta && item.meta.hasOwnProperty('access')) {
                    allowed = this.isAvailable(item.meta.access);
                }
                return allowed;
            },
            handleOpen() {
                //console.log('handleOpen');
            },
            handleClose() {
                //console.log('handleClose');
            },
            handleSelect: function (a, b) {
            },
            onResize() {
                this.windowWidth = window.innerWidth
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })

            const user = this.$auth.user();
            if (user) {
                this.sysUserName = user.name || '';
                this.sysUserAvatar = user.avatar || '';
            }
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        computed: {
            ...mapGetters(['coreIsCollapsed', 'isAvailable']),
            admin_routes() {
                return this.$router.options.routes[0].children.sort((a, b) => (a.order > b.order) ? 1 : -1)
            }
        },
        watch: {
            windowWidth: function (){
                if (this.windowWidth < 768 && this.coreIsCollapsed !== true) {
                    this[SET_COLLAPSE](true)
                }else if (this.windowWidth >= 768 && this.coreIsCollapsed !== false){
                    this[SET_COLLAPSE](false)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
