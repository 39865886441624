import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/message',
        name: 'Message',
        component: page('Message'),
        iconCls: 'el-icon-share',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'message'
        },
        order: 506
    }
]
