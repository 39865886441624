import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/reports',
        name: 'Reports',
        component: page('ReportList'),
        iconCls: 'el-icon-data-analysis',
        class: 'float-right',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'report'
        },
        order: 509,
        children: [
            {
                path: 'stat',
                component: page('ReportStat1'),
                name: 'Stat1',
            },
            {
                path: 'stat2',
                component: page('ReportStat2'),
                name: 'Stat2',
            }
        ]
    },
    {
        path: '/reports/:id',
        name: 'Show Report',
        component: page('ReportView'),
        hidden: true
    }
]
