import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/logs',
        name: 'Logs',
        component: page('LogList'),
        iconCls: 'el-icon-s-fold',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'logs'
        },
        order: 508
    },
    {
        path: '/logs/:id',
        name: 'Show Log',
        component: page('LogView'),
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'logs'
        },
        hidden: true
    }
]
