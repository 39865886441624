/*
	This will check to see if the user is access or not.
*/
export function requireAuth(to, from, next) {
    let access = ''

    if (to.meta.access) {
        access = to.meta.access
    }

    let tempRecipes = requireAuthCheck(access)

    if (tempRecipes.length) {
        next(undefined)
    } else {
        next('/admin')
    }
}

export function requireAuthCheck(access) {
    const user = Vue.$auth.user();

    let tempAccess = []

    if (user) {
        if (user.access == "sa" && user.id == 1) {
            return [1];
        }

        if (Array.isArray(access)) {
            let temp = []

            $.each(access, function (key, value) {
                console.log(user)
                temp = user.access.filter((item) => {
                    return item.includes(value)
                })
                if (temp.length)
                    tempAccess.push(temp[0])
            })
        } else if (Array.isArray(user.access)) {
            tempAccess = user.access.filter((item) => {
                return item.includes(access)
            })
        }
    }

    return tempAccess
}

export default {
    methods: {
        requireAuth,
        requireAuthCheck
    }
}
