import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/profile',
        name: 'Profile',
        component: page('Profile'),
        hidden: true
    },
    {
        path: '/setting',
        name: 'Setting',
        component: page('Setting'),
        order: 510,
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'settings'
        },

    },
];
