import helper from "../../includes/helpers/helpers";

/**
 * AutoImporting components
 * @param path
 * @returns {function(): *}
 */
const page = path => () => import(/* webpackChunkName: '' */ `./components/${path}`).then(m => m.default || m)

export const routes = [
    {
        path: '/record',
        name: 'Records',
        component: page('Record'),
        iconCls: 'el-icon-video-play',
        beforeEnter: helper.requireAuth,
        meta: {
            access: 'record'
        },
        order: 505
    }
]
