import * as types from './types'
import {actions} from './actions'

export const store = {
    state: {
        dutyPhones: [],
        dutyPhonesMeta: [],
        dutyPhonesLoading: true,
        daysWeekOptions: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    },
    getters: {
        dutyPhones: state => state.dutyPhones,
        dutyPhonesMeta: state => state.dutyPhonesMeta,
        dutyPhonesLoading: state => state.dutyPhonesLoading,
        daysWeekOptions: state => state.daysWeekOptions,

        changeWorkDays: (state) => (days) => {
            if (days.length === 7){
                return "Каждый день"
            }
            if (days.length === 0){
                return "Не выбрано"
            }

            let list = []
            $.each(days, function (key, val){
                list.push(state.daysWeekOptions[val])
            })

            return list.join(', ')
        }
    },
    mutations: {
        [types.DUTY_PHONE_OBTAIN](state, dutyPhones) {
            state.dutyPhones = dutyPhones
        },
        [types.DUTY_PHONE_CLEAR](state) {
            state.dutyPhones = []
        },
        [types.DUTY_PHONE_SET_LOADING](state, loading) {
            state.dutyPhonesLoading = loading
        },
        [types.DUTY_PHONE_META](state, meta) {
            state.dutyPhonesMeta = meta
        },
    },
    actions
}
